




































































import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'news-share',

  setup() {
    const chrome = useState(['chrome'])
    const stringsShared = chrome.chrome.value.data.i18n

    const items = ['linkedin', 'twitter', 'facebook']
    const openPopup = (url, title, width, height) => {
      const left = (window.screen.width - width) / 2
      const top = (window.screen.height - height) / 2

      return window.open(
        url,
        title,
        `status=no,resizable=yes,height=${height},width=${width},top=${top},left=${left},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no` // eslint-disable-line max-len
      )
    }

    const open = slug => {
      const { href } = window.location
      let url

      switch (slug) {
        case 'facebook':
          url = `https://www.facebook.com/sharer/sharer.php?u=${href}`
          openPopup(url, 'Share on Facebook', 575, 300)
          break
        case 'linkedin':
          url = `https://www.linkedin.com/shareArticle?mini=true&url=${href}`
          openPopup(url, 'Share on Linkedin', 550, 255)
          break
        case 'twitter':
          url = `https://twitter.com/intent/tweet?url=${href}`
          openPopup(url, 'Share on Twitter', 550, 255)
          break
        default:
      }
    }

    const copyUrl = () => {
      const fakeInput = document.createElement('input')
      const url = window.location.href

      document.body.appendChild(fakeInput)
      fakeInput.value = url
      fakeInput.select()
      document.execCommand('copy')
      document.body.removeChild(fakeInput)
    }

    const strReplace = (str, rep, repWith) => {
      const result = str.split(rep).join(repWith)

      return result
    }

    return {
      copyUrl,
      items,
      open,
      openPopup,
      stringsShared,
      strReplace,
    }
  },
})
