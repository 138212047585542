





















































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  setup() {
    return {
      ...useGetters(['chrome']),
    }
  },
})
