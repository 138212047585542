var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news-page"},[(_vm.content.breadcrumb)?_c('breadcrumb',{attrs:{"content":_vm.content.breadcrumb}}):_vm._e(),(_vm.content.items)?_c('div',{staticClass:"news"},[_c('div',{staticClass:"wrapper between-s mb-xl"},[_c('div',{staticClass:"header"},[_c('h1',{staticClass:"header__title mb-l",domProps:{"innerHTML":_vm._s(
            _vm.$options.filters && _vm.$options.filters.linebreak(_vm.content.title)
          )}}),(_vm.selectedCategories)?_c('div',{staticClass:"list mb-s"},[_c('span',{staticClass:"list__title"},[_vm._v(_vm._s(_vm.stringsShared.themes))]),_vm._l((_vm.selectedCategories),function(item,index){return _c('span',{key:("list-item_" + index)},[_vm._v(" "+_vm._s(item)+" ")])})],2):_vm._e()]),_c('div',{staticClass:"content row"},[_c('div',{staticClass:"grid-cards"},[(_vm.content.items.title)?_c('h3',{staticClass:"grid-cards__title"},[_vm._v(" "+_vm._s(_vm.content.items.title)+" ")]):_vm._e(),_c('ul',{staticClass:"grid-cards__items"},_vm._l((_vm.content.items.content),function(item,index){return _c('li',{key:("grid-cards__item_" + index),staticClass:"grid-cards__item"},[_c('card',{attrs:{"content":item}})],1)}),0),_c('div',{staticClass:"grid-cards__action mt-l"},[(
                _vm.content.items.currentPage < _vm.content.items.pages &&
                !_vm.content.items.isLastPage
              )?_c('div',{staticClass:"grid-cards__btn action--btn--link",on:{"click":function($event){_vm.newContent(
                  '?page=' + (parseFloat(_vm.content.items.currentPage) + 1)
                )}}},[_c('span',{staticClass:"action__label"},[_vm._v(_vm._s(_vm.stringsShared.readMore))])]):_vm._e()])])])])]):_c('div',{staticClass:"news-details"},[_c('div',{staticClass:"container wrapper"},[_c('div',{staticClass:"content row"},[_c('div',{staticClass:"col-xs-20 col-l-16"},[_c('MainTitle',{staticClass:"news-details__title",attrs:{"publication-date":_vm.content.publicationDate,"title":_vm.content.title,"hasSeparator":""}}),(_vm.content.picture)?_c('figure',{staticClass:"picture news-details__picture"},[_c('img',{attrs:{"alt":_vm.content.picture.alt || _vm.content.title,"src":_vm.imgUrl}})]):_vm._e(),_c('div',{staticClass:"news-details__wrapper mb-xxl"},[_c('div',{staticClass:"news-details__sidebar"},[(_vm.content.categories)?_c('NewsList',{attrs:{"items":_vm.content.categories.items,"title":_vm.content.categories.title,"iscategory":""}}):_vm._e(),(_vm.content.downloads)?_c('NewsList',{attrs:{"items":_vm.content.downloads.items,"title":_vm.content.downloads.title,"isdownloads":""}}):_vm._e(),_c('NewsShare')],1),_c('div',{staticClass:"news-details__content"},[(_vm.content.htmltext)?_c('g-wysiwyg',{attrs:{"wysiwyg":_vm.content.htmltext}}):_vm._e()],1)])],1),(_vm.content.related)?_c('GridCards',{attrs:{"content":_vm.content.related,"items":_vm.content.related.items}}):_vm._e()],1)])]),_c('contact',{staticClass:"contact mt-xxl",attrs:{"content":_vm.contactData.content,"onClick":_vm.showContact}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }