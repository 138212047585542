var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid-cards"},[(_vm.content.title)?_c('h3',{staticClass:"grid-cards__title"},[_vm._v(_vm._s(_vm.content.title))]):_vm._e(),_c('ul',{staticClass:"grid-cards__items"},_vm._l((_vm.items),function(item,index){return _c('li',{key:("grid-cards__item_" + index),staticClass:"grid-cards__item"},[_c('card',{attrs:{"content":item}})],1)}),0),_c('div',{staticClass:"grid-cards__action mt-l"},[(_vm.content.btn)?_c('g-action',{staticClass:"grid-cards__btn",attrs:{"content":{
        modifiers: ['btn'],
        label: _vm.content.btn.label,
        url: _vm.content.btn.url,
      }}}):(_vm.content.totalPosts > 4)?_c('g-action',{staticClass:"grid-cards__btn",attrs:{"content":{
        modifiers: ['btn'],
        label: _vm.stringsShared.seeMore,
        url: '?page=' + (parseFloat(_vm.content.currentPage) + 1),
      }}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }