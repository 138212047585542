import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=339e655f&scoped=true&"
import script from "./Contact.vue?vue&type=script&lang=ts&"
export * from "./Contact.vue?vue&type=script&lang=ts&"
import style0 from "./Contact.vue?vue&type=style&index=0&id=339e655f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339e655f",
  null
  
)

export default component.exports