






































































































































































































































































































import { defineComponent } from '@vue/composition-api'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Contact from '@/components/Contact.vue'
import ContactPanel from '@/components/ContactPanel.vue'
import GridCards from '@/components/GridCards.vue'
import Card from '@/components/Card.vue'
import MainTitle from '@/components/MainTitle.vue'
import NewsList from '@/components/news/List.vue'
import NewsShare from '@/components/news/Share.vue'

import { useActions, useState } from '@u3u/vue-hooks'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'news',
  components: {
    Breadcrumb,
    Contact,
    GridCards,
    Card,
    MainTitle,
    NewsList,
    NewsShare,
  },

  data() {
    return {
      content: { title: '', items: { content: [] } },
      contactData: { content: { contact: {}, cta: {} } },
      panelValues: { content: { contact: {}, cta: {} } },
      imgUrl: '',
      stringsShared: { themes: '', readMore: '' },
    }
  },

  methods: {
    ...mapMutations({
      setResource: 'SET_RESOURCE',
    }),
    getContactData() {
      axios
        .get(`${getApiUrl()}/pages/presse-et-actualites/`)
        .then(response => (this.contactData = response.data))
        .catch(error => (this.contactData = error.data))
    },
    newContent(count?: string | null) {
      let newItems = []
      axios
        .get(`${getApiUrl()}/news/${count}`)
        .then(response => {
          newItems = this.content.items.content.concat(
            response.data.content.items.content
          )
          this.content = response.data.content
          this.content.items.content = newItems
        })
        .catch(error => (this.content = error.data))
    },
    showContact(e) {
      e.preventDefault()
      const { showPanel } = useActions('sidePanel', ['showPanel'])
      showPanel({
        component: {
          template: ContactPanel,
        },
        content: {
          ...this.panelValues.content.cta,
          ...this.panelValues.content.contact,
        },
      })
    },
  },

  async created() {
    const state = useState(['resource'])
    const chrome = useState(['chrome'])
    const { fetchChrome } = useActions(['fetchChrome'])

    if (state.resource.value.content) {
      this.content = state.resource.value.content
      state.resource.value.meta.title = this.content.title

      this.imgUrl = state.resource.value.content.picture?.rawUrl
        ? `${process.env.VUE_APP_API_DOMAIN}${state.resource.value.content.picture.rawUrl}`
        : ''

      this.stringsShared = chrome.chrome.value.data.i18n
    } else {
      await fetchChrome(this.$i18n.locale)
      this.setResource(this.$router.$resource)

      this.content = state.resource.value.content
      state.resource.value.meta.title = this.content.title

      this.imgUrl = state.resource.value.content.picture?.rawUrl
        ? `${process.env.VUE_APP_API_DOMAIN}${state.resource.value.content.picture.rawUrl}`
        : ''

      this.stringsShared = chrome.chrome.value.data.i18n
    }

    this.getContactData()
  },

  mounted() {
    axios.get(`${getApiUrl()}/pages/presse-et-actualites/`).then(response => {
      this.panelValues = response.data
    })
  },

  setup(props, ctx) {
    const { $route } = ctx.root

    // Dev
    let selectedCategories = $route.query['categories[]']

    if ($route.query['categories[]']) {
      if (Array.isArray($route.query['categories[]'])) {
        selectedCategories = $route.query['categories[]']
      } else {
        selectedCategories = $route.query['categories[]'].split(' ')
      }
      console.log('--- QUERY ---', selectedCategories)
    }

    return {
      selectedCategories,
    }
  },
})
