























































































































































import dayjs from 'dayjs'
import { defineComponent } from '@vue/composition-api'
import { NewsCard } from '@/inc/types'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  props: {
    content: {
      type: Object as () => NewsCard,
      required: false,
    },
  },

  setup(props) {
    const chrome = useState(['chrome'])

    const imgUrl = props.content?.picture?.rawUrl
      ? `${process.env.VUE_APP_API_DOMAIN}${props.content.picture.rawUrl}`
      : ''

    return {
      dayjs,
      imgUrl,
      stringsShared: chrome.chrome.value.data.i18n,
    }
  },
})
