

































































































import { defineComponent } from '@vue/composition-api'
import Card from '@/components/Card.vue'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'grid-cards',
  components: {
    Card,
  },
  props: {
    content: Object,
    items: Array,
  },

  setup() {
    const chrome = useState(['chrome'])

    return {
      stringsShared: chrome.chrome.value.data.i18n,
    }
  },
})
