



















































































































import { defineComponent } from '@vue/composition-api'
import { Relay } from '@/inc/types'
import ChromeSocialNetwork from '@/components/chrome/SocialNetwork.vue'

export default defineComponent({
  name: 'contact',
  components: {
    ChromeSocialNetwork,
  },
  props: {
    onClick: {
      type: Function,
      required: false,
    },
    content: {
      type: Object as () => Relay,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
